import {
  useConsultListStore,
  useConsultMemberListStore,
  useConsultRequestListStore,
  useMemberListStore,
  useDeleteMemberListStore,
  useIntroListStore,
  useLikeListStore,
  useLikeMatchListStore,
  useCashListStore,
  useLikeHistoryListStore,
  useMileageHistoryListStore,
  useTotalListStore,
  useMatchMessageHistoryStore,
  useIncentiveHistoryStore,
  useMatchListHistoryStore,
  useAlarmListStore,
  useHaveBeenIntroducedListStore,
  useRefundMemberListStore,
} from "./state";

export function resetAllLists() {
  useConsultListStore.getState().resetList();
  useConsultMemberListStore.getState().resetList();
  useConsultRequestListStore.getState().resetList();
  useMemberListStore.getState().resetList();
  useRefundMemberListStore.getState().resetList();
  useDeleteMemberListStore.getState().resetList();
  useIntroListStore.getState().resetList();
  useLikeListStore.getState().resetList();
  useLikeMatchListStore.getState().resetList();
  useCashListStore.getState().resetList();
  useLikeHistoryListStore.getState().resetList();
  useMileageHistoryListStore.getState().resetList();
  useTotalListStore.getState().resetList();
  useMatchMessageHistoryStore.getState().resetList();
  useIncentiveHistoryStore.getState().resetList();
  useMatchListHistoryStore.getState().resetList();
  useAlarmListStore.getState().resetList();
  useHaveBeenIntroducedListStore.getState().resetList();
}
