import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import dotenv from "dotenv";
dotenv.config();

const meta = document.createElement("meta");
meta.name = "build-timestamp";
meta.content = process.env.REACT_APP_BUILD_TIMESTAMP || "unknown";
document.head.appendChild(meta);

const app = (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </DndProvider>
);

ReactDOM.render(app, document.getElementById("root"));
// serviceWorker.register();
// serviceWorker.register({
//   onUpdate: (registration) => {
//     if (registration && registration.waiting) {
//       registration.waiting.addEventListener("statechange", (event) => {
//         if (event.target.state === "activated") {
//           // 새로운 서비스 워커가 활성화되면 페이지를 새로고침
//           window.location.reload();
//         }
//       });
//       registration.waiting.postMessage({ type: "SKIP_WAITING" });
//     }
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
