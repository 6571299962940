import React, { useCallback, useEffect, useRef, useState } from "react";
import { useConsultListStore } from "../../store/zustand/state";
import { useHistory, Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import {  requestDelete, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import "./consult.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import moment from "moment";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import { isMobile } from "react-device-detect";
import axios from "axios";

function ConsultList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [username, setuserName] = useState("");
  const [usergender, setuserGender] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [kakaoTalkId, setKakaoTalkId] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [managers, setManagers] = useState([]);
  const [counselor, setCounselor] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [isMo, setIsMo] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const hours = [
    "전체",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
  ];
  const history = useHistory();
  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useConsultListStore();

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let managerType = localStorage.getItem("loginUserType");
    if (managerType &&  managerType === "manager") {
      history.push("/");
    } else {
      callManagerApi();
    }
    // callManagerApi();
  }, []);

  const columns = [
    {
      dataField: "action",
      text: "삭제",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleRejectClick(e, row, rowIndex)}
            className="btn btn-danger btn-sm"
          >
            삭제
          </button>
        );
      },
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "convertedDate",
      text: "신청일자",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "name",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "birth_year",
      text: "출생년도",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "status",
      text: "상태",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "phone",
      text: "연락처",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "kakaoTalkId",
      text: "카카오톡ID",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "date",
      text: "상담일자",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent ? cellContent : " "}
          </a>
        );
      },
    },
    {
      dataField: "time",
      text: "상담시간",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "counselor_assignment",
      text: "상담자 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "counselor_gender",
      text: "희망상담사 성별",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "subPath",
      text: "가입경로",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "recommender",
      text: "추천인",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/consult/read/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(`/api/counseling/list?${params}`);
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  const excelDown = async () => {
    try {
      const params = new URLSearchParams();
      const token = localStorage.getItem("merriageAgency-access");
      let headers = {};
      if (token) {
        headers = {
          "x-access-token": token,
        };
      }
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }

      axios({
        url: `${process.env.REACT_APP_API_HOST}/api/counseling/list/excel?${params}`,
        method: "GET",
        responseType: "blob",
        headers,
      }).then((response) => {
        const fileObjectUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = fileObjectUrl;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileObjectUrl);
      });
    } catch (error) {
      alert("Request failed.");
    }
  };

  useEffect(() => {
    setuserName(list.name);
    setGender(list.gender);
    setBirth(list.birth_year);
    setuserGender(list.counselor_gender);
    setActiveNum(list.page);
    setUserPhone(list.phone);
    setCounselor(list.counselor);
    setDate(list.counseling_date);
    setTime(list.counseling_time);
    setUserStatus(list.status);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(
      username,
      gender,
      birth,
      usergender,
      userPhone,
      date,
      time,
      1,
      counselor,
      userStatus
    );
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) => {
        const inputMoment = moment(item.createdAt);
        inputMoment.add(9, "hours");
        const todayMoment = moment();

        let tmp = inputMoment.format("YYYY/MM/DD");
        if (inputMoment.isSame(todayMoment, "day")) {
          // 오늘 날짜인 경우, 시:분:초 형식으로 표시
          tmp = inputMoment.format("HH시mm분ss초");
        }
        tmp = inputMoment.format("YYYY/MM/DD HH:mm:ss");
        arr.push({
          id: item.id,
          name: item.name,
          birth_year: item.birth_year,
          gender: item.gender,
          phone: item.phone,
          kakaoTalkId: item.kakaoTalkId,
          subPath: item.subPath,
          status:
            item.status === 1
              ? "신청"
              : item.status === 2
              ? "상담 확정"
              // : item.status === 3
              // ? "보류"
              : item.status === 4
              ? "상담 거절"
              : item.status === 5
              ? "추후 연락"
              : item.status === 6
              ? "연락중"
              : "알 수 없는 상태",
          education: item.education,
          counselor_gender: item.counselor_gender,
          counselor_assignment: item.counselor_assignment,
          ideal_appearance: item.ideal_appearance,
          ideal_ability: item.ideal_ability,
          ideal_character: item.ideal_character,
          recommender: item.recommender,
          createdAt: item.createdAt,
          date: item.consultation_date
            ? moment(item.consultation_date).format("YYYY-MM-DD")
            : "",
          time: item.consultation_date
            ? moment(item.consultation_date).format("HH:mm")
            : "",
          convertedDate: tmp,
          subPath : item.subPath,
        });
      });
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const handleRowClick = (e, row) => {
    window.location.href = `/consult/read/${row.id}`;
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(e, row, rowIndex);
    },
  };

  const handlename = (e) => {
    setuserName(e.target.value);
  };
  const handlegender = (e) => {
    setGender(e.target.value);
  };
  const handlebirth = (e) => {
    setBirth(e.target.value);
  };
  const handletime = (e) => {
    if (date) {
      setTime(e.target.value);
    } else {
      alert("시간 변경은 일자 필수 입니다.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  const genderChange = (e) => {
    setuserGender(e.target.value);
  };
  const handleUserStatus = (e) => {
    setUserStatus(e.target.value);
  };

  const phoneChange = (e) => {
    setUserPhone(e.target.value);
  };
  const counselorChange = (e) => {
    setCounselor(e.target.value);
  };

  const handleRejectClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (
      window.confirm(`
    이름 : ${row.name}
    출생년도 : ${row.birth_year}
    성별 : ${row.gender}
    연락처 : ${row.phone}
    카카오톡ID : ${row.kakaoTalkId}
    \n
    정말 삭제 하시겠습니까?`)
    ) {
      try {
        await requestDelete(`/api/counseling/remove/${row.id}`);
        alert("삭제 성공 하였습니다.");
        // window.location.reload();
        callInfo()
      } catch (error) {
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  const handleDate = (e) => {
    if (e.target.value) {
      setDate(moment(e.target.value).format("YYYY-MM-DD"));
      setShowPlaceholder(false);
      setTime("");
    } else {
      setDate("");
      setShowPlaceholder(true);
      setTime("");
    }
  };

  useEffect(() => {
    if (isMobile) {
      setIsMo(true);
    } else {
      setIsMo(false);
    }
  }, []);

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>상담요청 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            상담요청 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={username}
                                          onChange={handlename}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                      }}
                                    >
                                      <span>성별</span>
                                    </Col>
                                    <Col className="m-0 p-0 customSearchInput">
                                      <select
                                        id="counselor_genderLabel"
                                        className="noborder form-select h-100"
                                        value={gender}
                                        onChange={handlegender}
                                        style={{
                                          maxWidth: "9rem",
                                        }}
                                      >
                                        <option value="">전체</option>
                                        <option value="남자">남자</option>
                                        <option value="여자">여자</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>출생년도</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="출생년도를 입력해 주세요."
                                          aria-label="출생년도를 입력해 주세요."
                                          value={birth}
                                          onChange={handlebirth}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                      }}
                                    >
                                      <span>희망 상담사 성별</span>
                                    </Col>
                                    <Col className="m-0 p-0 customSearchInput">
                                      <select
                                        id="counselor_genderLabel"
                                        className="noborder form-select h-100"
                                        value={usergender}
                                        onChange={genderChange}
                                        style={{
                                          maxWidth: "9rem",
                                        }}
                                      >
                                        <option value="">전체</option>
                                        <option value="남자">남자</option>
                                        <option value="여자">여자</option>
                                        <option value="무관">무관</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>전화번호</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="phone"
                                          id="nameLabel"
                                          placeholder="전화번호를 입력해 주세요."
                                          aria-label="전화번호를 입력해 주세요."
                                          value={userPhone}
                                          onChange={phoneChange}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>매니저</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="counselor_genderLabel"
                                          className="noborder form-select h-100"
                                          value={counselor}
                                          onChange={(e) =>
                                            setCounselor(e.target.value)
                                          }
                                          style={{
                                            maxWidth: "9rem",
                                          }}
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                      }}
                                    >
                                      <span>상태</span>
                                    </Col>
                                    <Col className="m-0 p-0 customSearchInput">
                                      <select
                                        id="counselor_userStatus"
                                        className="noborder form-select h-100"
                                        value={userStatus}
                                        onChange={handleUserStatus}
                                        style={{
                                          maxWidth: "9rem",
                                        }}
                                      >
                                        <option value="">전체</option>
                                        <option value="1">신청</option>
                                        <option value="6">연락중</option>
                                        <option value="2">상담 확정</option>
                                        {/* <option value="3">보류</option> */}
                                        <option value="4">상담 거절</option>
                                        <option value="5">추후 연락</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>상담일자</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div
                                        className="input-group h-100"
                                        style={{
                                          position: "relative",
                                          paddingLeft: "1px",
                                        }}
                                      >
                                        {isMo
                                          ? showPlaceholder && (
                                              <label
                                                htmlFor="date"
                                                style={{
                                                  position: "absolute",
                                                  left: "10px",
                                                  top: "0",
                                                  color: "#888",
                                                  pointerEvents: "none",
                                                  userSelect: "none",
                                                  height: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  margin: "0px",
                                                  zIndex: 10,
                                                  backgroundColor: "#fff",
                                                }}
                                              >
                                                연도.월.일.
                                              </label>
                                            )
                                          : null}
                                        <input
                                          type="date"
                                          className="noborder js-input-mask form-control"
                                          name="firstDate"
                                          id="firstDateLabel"
                                          min={moment().format("YYYY-MM-DD")}
                                          max="9999-12-31"
                                          // className="js-input-mask form-control customSearchInput h-100"
                                          value={date}
                                          onChange={handleDate}
                                          onKeyDown={handleKeyPress}
                                          style={{
                                            maxWidth: "9rem",
                                          }}
                                          data-placeholder="날짜 선택"
                                        />
                                        <select
                                          className="noborder form-select"
                                          name="firstDateTime"
                                          style={{
                                            maxWidth: "5rem",
                                            border: "none",
                                          }}
                                          value={time}
                                          onChange={handletime}
                                        >
                                          {hours?.map((item, i) => {
                                            if (item === "전체") {
                                              return (
                                                <option value="" key={i}>
                                                  전체
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option value={item} key={i}>
                                                  {item}시
                                                </option>
                                              );
                                            }
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-50"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                    <Button
                                      className="w-50"
                                      color="warning"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => excelDown()}
                                    >
                                      다운로드
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  rowEvents={rowEvents} // rowEvents 속성 추가
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsultList;
