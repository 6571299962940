import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
// import "./review.scss";
import {
  Button,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";

export default function ImageView(props, params) {
  const imageType = props.match.path.indexOf('mainImage') !== -1 ? 1 : 2;
  const boardNo = props.match.params.id;
  const [ viewData, setViewData ] = useState(null) 

  async function getData(){
    const token = localStorage.getItem("merriageAgency-access");

    const data = await fetch(`${process.env.REACT_APP_APIURL}/api/image/view?id=${boardNo}`, {
        headers: {
        'x-access-token': token,
        withCredentials: true,
      },
      method: 'GET',
    });
    const result = await data.json();
    setViewData(result.data.data[0]);
  }

  async function imageDel(){
    if(window.confirm('선택된 데이터가 삭제됩니다.')){
      const token = localStorage.getItem("merriageAgency-access");
      const data = await fetch(`${process.env.REACT_APP_APIURL}/api/image/del`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          withCredentials: true,
        },
        method: 'POST',
        body: JSON.stringify({no : boardNo}),
      });
      
      const result = await data.json();
      console.log(result.data === 1)
      if(result.data.data === 1){
        window.location.href = `/${imageType === 1 ? 'main' : 'party'}Image/list`;
      }else{}
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (!viewData) {
    return (
      <div className="custompage-content">
        <p>Loading...</p>
      </div>
    );
  }

  return(
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>{imageType === 1 ? '메인' : '파티'} 이미지</title>
        </MetaTags>
        <div className="row">
          <div className="col-12 col">
            <div className="card">
              <div className="card-body">
                <div><h2>{imageType === 1 ? '메인' : '파티'} 이미지</h2></div>
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <p><label for="firstNameLabel" class="labelText">PC 이미지</label></p>
                      <img src={`https://d3txsylzm2ga7z.cloudfront.net/${viewData.pc_src}`} alt="pc_src" className="lst_thumnail" />
                    </div>
                  </Col>
                </Row>
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <p><label for="firstNameLabel" class="labelText">모바일 이미지</label></p>
                      <img src={`https://d3txsylzm2ga7z.cloudfront.net/${viewData.m_src}`} alt="m_src" className="lst_thumnail" />
                    </div>
                  </Col>
                </Row>
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <p><label for="firstNameLabel" class="labelText">출력 순서</label></p>
                      {viewData.index}
                    </div>
                  </Col>
                </Row>
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <p><label for="firstNameLabel" class="labelText">게시물 숨김</label></p>
                      {viewData.view === 1 ? 'X' : 'O'}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  color="primary"
                  className="me-2"
                  onClick={e => { window.location.href = `/${imageType === 1 ? 'main' : 'party'}Image/edit/${viewData.no}`}}
                >
                  수정
                </Button>
                <Button
                  color="primary"
                  className="me-2"
                  onClick={imageDel}
                >
                  삭제
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
