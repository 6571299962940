import moment from "moment";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useConsultListStore = create(
  persist(
    (set) => ({
      list: {
        name: "",
        gender: "",
        birth_year: "",
        counselor_gender: "",
        phone: "",
        counseling_date: "",
        counseling_time: "",
        page: 1,
        counselor: "",
        status: "",
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (
        name,
        gender,
        birth_year,
        counselor_gender,
        phone,
        counseling_date,
        counseling_time,
        page,
        counselor,
        status
      ) =>
        set((state) => ({
          list: {
            ...state.list,
            name,
            gender,
            birth_year,
            counselor_gender,
            phone,
            counseling_date,
            counseling_time,
            page,
            counselor,
            status,
          },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            name: "",
            gender: "",
            birth_year: "",
            counselor_gender: "",
            phone: "",
            counseling_date: "",
            counseling_time: "",
            page: 1,
            counselor: "",
            status: "",
          },
        }),
    }),
    {
      name: "consultList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useConsultMemberListStore = create(
  persist(
    (set) => ({
      list: { name: "", page: 1, counselor: "",phone:"" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (name, page, counselor,phone) =>
        set((state) => ({
          list: { ...state.list, name, page, counselor,phone },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () => set({ list: { name: "", page: 1, counselor: "" ,phone:""} }),
    }),
    {
      name: "consultMemberList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useConsultRequestListStore = create(
  persist(
    (set) => ({
      list: { name: "", page: 1, counselor: "" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (name, page, counselor) =>
        set((state) => ({
          list: { ...state.list, name, page, counselor },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () => set({ list: { name: "", page: 1, counselor: "" } }),
    }),
    {
      name: "consultRequestList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useMemberListStore = create(
  persist(
    (set) => ({
      list: {
        name: "",
        page: 1,
        counselor: "",
        userMarriageFee: "",
        status: "",
        birthday: "",
        phone: "",
        sort: "agreeDate",
        gender: "",
        minAge: "",
        maxAge: "",
        minHeight: "",
        maxHeight: "",
        minYearIncome: "",
        maxYearIncome: "",
        minFinalStage: "",
        maxFinalStage: "",
        minMovableAsset: "",
        maxMovableAsset: "",
        minImmovableAsset: "",
        maxImmovableAsset: "",
        minParentAsset: "",
        maxParentAsset: "",
        minParentImmovableAmount: "",
        maxParentImmovableAmount: "",
        home: "",
        minAppearanceScore: "",
        maxAppearanceScore: "",
        minSpecsScore: "",
        maxSpecsScore: "",
        minWealthScore: "",
        maxWealthScore: "",
        smoke: "",
        homeSido: "",
        homeRegionCode: "",
        familyType: "",
        religion: [],
        drink: "",
        marriage:"",
        homeSidoList: [],
        firstLoad :true
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (
        name,
        page,
        counselor,
        userMarriageFee,
        status,
        birthday,
        phone,
        sort,
        gender,
        minAge,
        maxAge,
        minHeight,
        maxHeight,
        minYearIncome,
        maxYearIncome,
        minFinalStage,
        maxFinalStage,
        minMovableAsset,
        maxMovableAsset,
        minImmovableAsset,
        maxImmovableAsset,
        minParentAsset,
        maxParentAsset,
        minParentImmovableAmount,
        maxParentImmovableAmount,
        home,
        minAppearanceScore,
        maxAppearanceScore,
        minSpecsScore,
        maxSpecsScore,
        minWealthScore,
        maxWealthScore,
        smoke,
        homeSido,
        homeRegionCode,
        familyType,
        religion,
        drink,
        marriage,
        homeSidoList,
        firstLoad
      ) =>
        set((state) => ({
          list: {
            ...state.list,
            name,
            page,
            counselor,
            userMarriageFee,
            status,
            birthday,
            phone,
            sort,
            gender,
            minAge,
            maxAge,
            minHeight,
            maxHeight,
            minYearIncome,
            maxYearIncome,
            minFinalStage,
            maxFinalStage,
            minMovableAsset,
            maxMovableAsset,
            minImmovableAsset,
            maxImmovableAsset,
            minParentAsset,
            maxParentAsset,
            minParentImmovableAmount,
            maxParentImmovableAmount,
            home,
            minAppearanceScore,
            maxAppearanceScore,
            minSpecsScore,
            maxSpecsScore,
            minWealthScore,
            maxWealthScore,
            smoke,
            homeSido,
            homeRegionCode,
            familyType,
            religion,
            drink,
            marriage,
            homeSidoList,
            firstLoad
          },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            name: "",
            page: 1,
            counselor: "",
            userMarriageFee: "",
            status: "",
            birthday: "",
            phone: "",
            sort: "agreeDate",
            gender: "",
            minAge: "",
            maxAge: "",
            minHeight: "",
            maxHeight: "",
            minYearIncome: "",
            maxYearIncome: "",
            minFinalStage: "",
            maxFinalStage: "",
            minMovableAsset: "",
            maxMovableAsset: "",
            minImmovableAsset: "",
            maxImmovableAsset: "",
            minParentAsset: "",
            maxParentAsset: "",
            minParentImmovableAmount: "",
            maxParentImmovableAmount: "",
            home: "",
            minAppearanceScore: "",
            maxAppearanceScore: "",
            minSpecsScore: "",
            maxSpecsScore: "",
            minWealthScore: "",
            maxWealthScore: "",
            smoke: "",
            homeSido: "",
            homeRegionCode: "",
            familyType: "",
            religion: [],
            drink: "",
            marriage:"",
            homeSidoList: [],
            firstLoad :true
          },
        }),
    }),
    {
      name: "memberList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);



export const useRefundMemberListStore = create(
  persist(
    (set) => ({
      list: {
        name: "",
        page: 1,
        counselor: "",
        userMarriageFee: "",
        status: "refund-all",
        birthday: "",
        phone: "",
        sort: "agreeDate",
        gender: "",
        // minAge: "",
        // maxAge: "",
        // minHeight: "",
        // maxHeight: "",
        // minYearIncome: "",
        // maxYearIncome: "",
        // minFinalStage: "",
        // maxFinalStage: "",
        // minMovableAsset: "",
        // maxMovableAsset: "",
        // minImmovableAsset: "",
        // maxImmovableAsset: "",
        // minParentAsset: "",
        // maxParentAsset: "",
        // minParentImmovableAmount: "",
        // maxParentImmovableAmount: "",
        // home: "",
        // minAppearanceScore: "",
        // maxAppearanceScore: "",
        // minSpecsScore: "",
        // maxSpecsScore: "",
        // minWealthScore: "",
        // maxWealthScore: "",
        // smoke: "",
        // homeSido: "",
        // homeRegionCode: "",
        // familyType: "",
        // religion: [],
        // drink: "",
        // marriage:"",
        // homeSidoList: [],
        firstLoad :true
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (
        name,
        page,
        counselor,
        userMarriageFee,
        status,
        birthday,
        phone,
        sort,
        gender,
        // minAge,
        // maxAge,
        // minHeight,
        // maxHeight,
        // minYearIncome,
        // maxYearIncome,
        // minFinalStage,
        // maxFinalStage,
        // minMovableAsset,
        // maxMovableAsset,
        // minImmovableAsset,
        // maxImmovableAsset,
        // minParentAsset,
        // maxParentAsset,
        // minParentImmovableAmount,
        // maxParentImmovableAmount,
        // home,
        // minAppearanceScore,
        // maxAppearanceScore,
        // minSpecsScore,
        // maxSpecsScore,
        // minWealthScore,
        // maxWealthScore,
        // smoke,
        // homeSido,
        // homeRegionCode,
        // familyType,
        // religion,
        // drink,
        // marriage,
        // homeSidoList,
        firstLoad
      ) =>
        set((state) => ({
          list: {
            ...state.list,
            name,
            page,
            counselor,
            userMarriageFee,
            status,
            birthday,
            phone,
            sort,
            gender,
            // minAge,
            // maxAge,
            // minHeight,
            // maxHeight,
            // minYearIncome,
            // maxYearIncome,
            // minFinalStage,
            // maxFinalStage,
            // minMovableAsset,
            // maxMovableAsset,
            // minImmovableAsset,
            // maxImmovableAsset,
            // minParentAsset,
            // maxParentAsset,
            // minParentImmovableAmount,
            // maxParentImmovableAmount,
            // home,
            // minAppearanceScore,
            // maxAppearanceScore,
            // minSpecsScore,
            // maxSpecsScore,
            // minWealthScore,
            // maxWealthScore,
            // smoke,
            // homeSido,
            // homeRegionCode,
            // familyType,
            // religion,
            // drink,
            // marriage,
            // homeSidoList,
            firstLoad
          },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            name: "",
            page: 1,
            counselor: "",
            userMarriageFee: "",
            status: "refund-all",
            birthday: "",
            phone: "",
            sort: "agreeDate",
            gender: "",
            // minAge: "",
            // maxAge: "",
            // minHeight: "",
            // maxHeight: "",
            // minYearIncome: "",
            // maxYearIncome: "",
            // minFinalStage: "",
            // maxFinalStage: "",
            // minMovableAsset: "",
            // maxMovableAsset: "",
            // minImmovableAsset: "",
            // maxImmovableAsset: "",
            // minParentAsset: "",
            // maxParentAsset: "",
            // minParentImmovableAmount: "",
            // maxParentImmovableAmount: "",
            // home: "",
            // minAppearanceScore: "",
            // maxAppearanceScore: "",
            // minSpecsScore: "",
            // maxSpecsScore: "",
            // minWealthScore: "",
            // maxWealthScore: "",
            // smoke: "",
            // homeSido: "",
            // homeRegionCode: "",
            // familyType: "",
            // religion: [],
            // drink: "",
            // marriage:"",
            // homeSidoList: [],
            firstLoad :true
          },
        }),
    }),
    {
      name: "memberRefundList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useDeleteMemberListStore = create(
  persist(
    (set) => ({
      list: {
        name: "",
        page: 1,
        status: "withdrawal",
        phone: "",
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (name, page, status, phone) =>
        set((state) => ({
          list: {
            ...state.list,
            name,
            page,
            status,
            phone,
          },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            name: "",
            page: 1,
            status: "withdrawal",
            phone: "",
          },
        }),
    }),
    {
      name: "deleteMemberList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useIntroListStore = create(
  persist(
    (set) => ({
      list: { page: 1, name: "", manager: "" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page, name, manager) =>
        set((state) => ({
          list: { ...state.list, page, name, manager },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () => set({ list: { page: 1, name: "", manager: "" } }),
    }),
    {
      name: "introList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useLikeListStore = create(
  persist(
    (set) => ({
      list: { page: 1, name: "", manager: "" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page, name, manager) =>
        set((state) => ({
          list: { ...state.list, page, name, manager },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () => set({ list: { page: 1, name: "", manager: "" } }),
    }),
    {
      name: "likeList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useLikeMatchListStore = create(
  persist(
    (set) => ({
      list: { page: 1, name: "", manager: "", phone: "" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page, name, manager, phone) =>
        set((state) => ({
          list: { ...state.list, page, name, manager, phone },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({ list: { page: 1, name: "", manager: "", phone: "" } }),
    }),
    {
      name: "likeMatchList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useAlarmListStore = create(
  persist(
    (set) => ({
      list: { page: 1, name: "", manager: "", phone: "" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page, name, manager, phone) =>
        set((state) => ({
          list: { ...state.list, page, name, manager, phone },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({ list: { page: 1, name: "", manager: "", phone: "" } }),
    }),
    {
      name: "AlarmList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useIntroMemberListStore = create(
    persist(
        (set) => ({
            list: { page: 1,
                // name: "", counselor: "", startDate: "", endDate: ""
            },
            // userInfo 상태를 업데이트하는 액션
            updateList: (page
                         // , name, counselor, startDate, endDate
            ) =>
                set((state) => ({
                    list: { ...state.list, page,
                        // name, counselor, startDate, endDate
                    },
                })),
            updateActiveNum: (page) =>
                set((state) => ({
                    list: { ...state.list, page },
                })),
            resetList: () =>
                set({
                    list: {
                        page: 1,
                        // name: "",
                        // counselor: "",
                        // startDate: "",
                        // endDate: "",
                    },
                }),
        }),
        {
            name: "introMemberList", // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        }
    )
);


export const useMatchStoreListStore = create(
  persist(
      (set) => ({
          list: { page: 1,
              // name: "", counselor: "", startDate: "", endDate: ""
          },
          // userInfo 상태를 업데이트하는 액션
          updateList: (page
                       // , name, counselor, startDate, endDate
          ) =>
              set((state) => ({
                  list: { ...state.list, page,
                      // name, counselor, startDate, endDate
                  },
              })),
          updateActiveNum: (page) =>
              set((state) => ({
                  list: { ...state.list, page },
              })),
          resetList: () =>
              set({
                  list: {
                      page: 1,
                      // name: "",
                      // counselor: "",
                      // startDate: "",
                      // endDate: "",
                  },
              }),
      }),
      {
          name: "matchStoreList", // name of the item in the storage (must be unique)
          storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
  )
);

export const useRecieveLikeMemberListStore = create(
  persist(
      (set) => ({
          list: { page: 1,
            pageSize:10,
              // name: "", counselor: "", startDate: "", endDate: ""
          },
          // userInfo 상태를 업데이트하는 액션
          updateList: (page
                       // , name, counselor, startDate, endDate
          ) =>
              set((state) => ({
                  list: { ...state.list, page,
                      // name, counselor, startDate, endDate
                  },
              })),
          updateActiveNum: (page) =>
              set((state) => ({
                  list: { ...state.list, page },
              })),
          resetList: () =>
              set({
                  list: {
                      page: 1,
                      // name: "",
                      // counselor: "",
                      // startDate: "",
                      // endDate: "",
                  },
              }),
      }),
      {
          name: "recieveLikeMemberList", // name of the item in the storage (must be unique)
          storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
  )
);


export const useSentLikeMemberListStore = create(
  persist(
      (set) => ({
          list: { page: 1,
            pageSize:10,
              // name: "", counselor: "", startDate: "", endDate: ""
          },
          // userInfo 상태를 업데이트하는 액션
          updateList: (page
                       // , name, counselor, startDate, endDate
          ) =>
              set((state) => ({
                  list: { ...state.list, page,
                      // name, counselor, startDate, endDate
                  },
              })),
          updateActiveNum: (page) =>
              set((state) => ({
                  list: { ...state.list, page },
              })),
          resetList: () =>
              set({
                  list: {
                      page: 1,
                      // name: "",
                      // counselor: "",
                      // startDate: "",
                      // endDate: "",
                  },
              }),
      }),
      {
          name: "sentLikeMemberList", // name of the item in the storage (must be unique)
          storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
  )
);

export const useHaveBeenIntroducedListStore = create(
    persist(
        (set) => ({
            list: { page: 1,
                // name: "", counselor: "", startDate: "", endDate: ""
            },
            // userInfo 상태를 업데이트하는 액션
            updateList: (page
                         // , name, counselor, startDate, endDate
            ) =>
                set((state) => ({
                    list: { ...state.list, page,
                        // name, counselor, startDate, endDate
                    },
                })),
            updateIntroudcedList: (page
                          // , name, counselor, startDate, endDate
            ) =>
                set((state) => ({
                    list: { ...state.list, page,
                        // name, counselor, startDate, endDate
                    },
                })),
            updateActiveNum: (page) =>
                set((state) => ({
                    list: { ...state.list, page },
                })),
            resetList: () =>
                set({
                    list: {
                        page: 1,
                        // name: "",
                        // counselor: "",
                        // startDate: "",
                        // endDate: "",
                    },
                }),
        }),
        {
            name: "HaveBeenIntroducedList", // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        }
    )
);

export const useCashListStore = create(
  persist(
    (set) => ({
      list: { page: 1, name: "", counselor: "", startDate: "", endDate: "" },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page, name, counselor, startDate, endDate) =>
        set((state) => ({
          list: { ...state.list, page, name, counselor, startDate, endDate },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            page: 1,
            name: "",
            counselor: "",
            startDate: "",
            endDate: "",
          },
        }),
    }),
    {
      name: "cashList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useLikeHistoryListStore = create(
  persist(
    (set) => ({
      list: { page: 1 },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () => set({ list: { page: 1 } }),
    }),
    {
      name: "likeHistoryList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useMileageHistoryListStore = create(
  persist(
    (set) => ({
      list: { page: 1 },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () => set({ list: { page: 1 } }),
    }),
    {
      name: "mileageHistoryList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useTotalListStore = create(
  persist(
    (set) => ({
      list: {
        startDate: "",
        endDate: "",
        page: 1,
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (startDate, endDate, page) =>
        set((state) => ({
          list: { ...state.list, startDate, endDate, page },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            startDate: "",
            endDate: "",
            page: 1,
          },
        }),
    }),
    {
      name: "TotalList", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useMatchMessageHistoryStore = create(
  persist(
    (set) => ({
      list: {
        page: 1,
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            page: 1,
          },
        }),
    }),
    {
      name: "MatchMessageHistory", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useIncentiveHistoryStore = create(
  persist(
    (set) => ({
      list: {
        page: 1,
        startDate: "",
        endDate: "",
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page, startDate, endDate) =>
        set((state) => ({
          list: { ...state.list, page, startDate, endDate },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            page: 1,
            startDate: "",
            endDate: "",
          },
        }),
    }),
    {
      name: "IncentiveHistory", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useMatchListHistoryStore = create(
  persist(
    (set) => ({
      list: {
        page: 1,
      },
      // userInfo 상태를 업데이트하는 액션
      updateList: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      updateActiveNum: (page) =>
        set((state) => ({
          list: { ...state.list, page },
        })),
      resetList: () =>
        set({
          list: {
            page: 1,
          },
        }),
    }),
    {
      name: "MatchListHistory", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
