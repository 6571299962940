import React, { useCallback, useEffect, useRef, useState } from "react";
import { useConsultMemberListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import { adminUserPut, requestGet, requestDelete } from "../../apis/request";

import { useHistory, Link } from "react-router-dom";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import "./consult.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";

function ConsultMemberList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [username, setuserName] = useState("");
  const [phone, setuserPhone] = useState("");
  const [counselor, setCounselor] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [managers, setManagers] = useState([]);
  const history = useHistory();
  const [tableHeight, setTableHeight] = useState(0);
  const [managerRole, setManagerRole] = useState(false);
  const [checkStatus, setCheckStatus] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useConsultMemberListStore();

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const statusUpdate = async (id,status) => {
    if (window.confirm("수정 하시겠습니까?")) {

        try {
          let body ={
            id: Number(id),
            counselingStatus:status
          }
          await adminUserPut(`/api/counseling/counselstatus`, body);
          alert("변경 성공 하였습니다.");
          callInfo()
        } catch (error) {
          console.log(error);
          alert("변경 실패 하였습니다.");
        }
    }
  };


  useEffect(() => {
    callManagerApi();
  }, []);

  const handleAcceptClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("승인 하시겠습니까?")) {
      try {
        let body = {
          id: row.userId,
        };
        await adminUserPut(`/api/member/ready/accept`, body);
        alert("승인 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("승인 실패 하였습니다.");
      }
    }
  };

  function getMemberUrl(userId,status){
    let url=``;
    if (status){
  
      switch (status) {
        case 'ready':
          url=`/user/${userId}/consult`
         break;
        case 'request':
           url=`/user/${userId}/wait`
          break;
        case 'active':
        case 'sleep':
        case 'sleep2':
        case 'matched':
        case 'matched2':
        case 'expired':
        case 'refund':
        case 'refund-pending':
          url=`/member/detail/${userId}`
          break;                
        case 'withdrawal':
          break;
        default:
          break;
      }
    }
    return url
  }

  const handleWidthrawalClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        let body = {
          id: row.userId,
        };
        await requestDelete(`/api/member/${row.userId}`);
        alert("삭제 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("삭제 실패 하였습니다.");
      }
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "name",
      text: "이름",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "phone",
      text: "연락처",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "recommendCode",
      text: "추천코드",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "birthday",
      text: "생년월일",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "regDate",
      text: "가입일시",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/user/${row.userId}/consult`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    // {
    //   dataField: "statusCheck",
    //   text: "상담 현황",
    //   formatter: (cellContent, row, rowIndex) => {
    //     let status=0
    //     if ([5,3,4,7].includes(row.requestStatus)){
    //       status=row.requestStatus
    //     }
    //     return (
    //       <select
    //       className="form-select customInput"
    //       name="ideal_ability"
    //       style={{ maxWidth: "10rem" }}
    //       value={status}
    //       onChange={(e) => statusUpdate(row.id,e.target.value)}
    //     >
    //       <option value={0} >상담 요청</option>
    //       <option value={5}>상담 완료</option>
    //       <option value={3}>상담 보류</option>
    //       <option value={4}>상담 반려</option>
    //       <option value={7}>상담 취소</option>
    //     </select>
    //     );
    //   },
    // },
    {
      dataField: "action1",
      text: "승인",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleAcceptClick(e, row, rowIndex)}
            className="btn btn-primary btn-sm"
          >
            승인
          </button>
        );
      },
    },
    {
      dataField: "action2",
      text: "삭제",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleWidthrawalClick(e, row, rowIndex)}
            className="btn btn-dark btn-sm"
          >
            삭제
          </button>
        );
      },
    },
  ];
  const columns2 = [
    {
      dataField: "id",
      text: "Id",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);


          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "userId",
      text: "회원Id",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {

        if (row.userId==='생성'){

          let url=process.env.REACT_APP_API_WEP+'/register'
          let params=''
          if (row.regCode&&row.regCode!=='없음'){
            params+=`regCode=${row.regCode}`
          }
          if (row.name&&row.name!=='없음'){
            if(params&&params.length>0){
              params+='&'
            }
            params+=`name=${row.name}`
          }

          if (row.phone&&row.phone!=='없음'){
            if(params&&params.length>0){
              params+='&'
            }
            params+=`phone=${row.phone}`
          }
          if (row.gender&&row.gender!=='없음'){
            if(params&&params.length>0){
              params+='&'
            }
            params+=`gender=${row.gender}`
          }
          if (params&&params.length>0){
            url+=`?${params}`
          }


          return<a className="btn btn-sm btn-primary" href={url} target="_blank" rel="noopener noreferrer">
  {cellContent}
</a>

        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }


      },
    },
    {
      dataField: "hasProfile",
      text: "차트작성",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    
    {
      dataField: "name",
      text: "이름",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "phone",
      text: "연락처",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "recommendCode",
      text: "추천코드",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "birthday",
      text: "생년월일",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "regDate",
      text: "가입일시",
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        if (!row.userId||row.userId==='생성'){
          return cellContent
        }else{
          let url=getMemberUrl(row.userId,row.status);
          return (
            <a href={url} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
              {cellContent}
            </a>
          );
        }
      },
    },
    {
      dataField: "statusCheck",
      text: "상담 현황",
      formatter: (cellContent, row, rowIndex) => {
        let status=row.counselingStatus;

        return (
          <select
          className="form-select customInput"
          name="ideal_ability"
          style={{ maxWidth: "10rem" }}
          value={status}
          onChange={(e) => statusUpdate(row.id,e.target.value)}
        >
          <option value={1} >상담 요청</option>
          <option value={2}>상담 완료</option>
          <option value={3}>상담 보류</option>
          <option value={4}>상담 반려</option>
          <option value={5}>상담 취소</option>
        </select>
        );
//         if (row.userId==='생성'){
//           return (
//             <select
//             className="form-select customInput"
//             name="ideal_ability"
//             style={{ maxWidth: "10rem" }}
//             value={status}
//             onChange={(e) => statusUpdate(row.id,e.target.value)}
//           >
//             <option value={0} >상담 요청</option>
//             <option value={5}>상담 완료</option>
//             <option value={3}>상담 보류</option>
//             <option value={4}>상담 반려</option>
//             <option value={7}>상담 취소</option>
//           </select>
//           );
//         }else{
//           return (
// <div 
//   className=""
//   style={{ maxWidth: "10rem" }}
// >
//   {status === 0 && "상담 요청"}
//   {status === 5 && "상담 완료"}
//   {status === 3 && "상담 보류"}
//   {status === 4 && "상담 반려"}
//   {status === 7 && "상담 취소"}
// </div>
//           );
//         }

      },
    },
    {
      dataField: "action1",
      text: "승인",
      formatter: (cellContent, row, rowIndex) => {

        if (row.userId==='생성'){
          return <></>
        }else if (row.status==='ready'){
          return (
            <button
              onClick={(e) => handleAcceptClick(e, row, rowIndex)}
              className="btn btn-primary btn-sm"
            >
              승인
            </button>
          );
        }else{
          let status=''
          switch (row.status) {
            case 'request':
              status='승인대기'
              break;
            case 'active':
              status='활동'
              break;
            case 'sleep':
            case 'sleep2':
              status='휴면'
              break;
            case 'matched':
            case 'matched2':
              status='교제중'
              break;
            case 'withdrawal':
              status='탈퇴'
              break;
            case 'refund':
              status='환불'
              break;
            case 'refund-pending':
              status='환불 예정'
              break;
            case 'expired':
              status='만료'
              break;
            default:
              
              break;
          }
          return <div>{status}</div>
        }

      },
    },
    // {
    //   dataField: "action2",
    //   text: "삭제",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <button
    //         onClick={(e) => handleWidthrawalClick(e, row, rowIndex)}
    //         className="btn btn-dark btn-sm"
    //       >
    //         삭제
    //       </button>
    //     );
    //   },
    // },
  ];
  const DeleteButton=    {
    dataField: "action2",
    text: "삭제",
    formatter: (cellContent, row, rowIndex) => {

      if (row.userId==='생성'){
        return <></>
      } else if (row.status==='ready'){
        return (
          <button
          onClick={(e) => handleWidthrawalClick(e, row, rowIndex)}
          className="btn btn-dark btn-sm"
        >
          삭제
        </button>
        );
      }else{
        return <></>
      }
    },
  }
  if (!managerRole){
    columns2.push(DeleteButton) 
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      let url='/api/member/ready/list'
      // if (managerRole){
      //   url='/api/member/ready/list/v2'
      // }
          url='/api/member/ready/list/v2'
      const memberResponse = await requestGet(
        `${url}?${params}`
      );
      if(memberResponse && memberResponse.data){
        console.log("memberResponse",memberResponse);
        setMemberList(memberResponse?.data);
        // setMemberList([]);
      } else {
        setMemberList([]);
      }
      
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    // setManagerRole(localStorage.getItem("loginUserType") === "manager");
    setuserName(list.name);
    setActiveNum(list.page);
    setCounselor(list.counselor);
    setuserPhone(list.phone);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(username, 1, counselor,phone);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>{
        let userId=item.id;
        // if (managerRole){
        //   userId=item.userId === "없음" ?"생성" : item.userId
        // }
        userId=item.userId === "없음" ?"생성" : item.userId
        arr.push({
          id: item.id,
          userId,
          // userId: item.id,//예전 api 로 변경
          name: item.name,
          birthday: item.birthday === "Invalid date" ? "" : item.birthday,
          gender: item.gender,
          phone: item.phone,
          recommender: item.recommender,
          regDate: item.regDate,
          recommendCode: item.recommendCode,
          recommendId: item.recommendId,
          hasProfile: isNaN(item.userId) ? 'X' : 'O',
          status:item.status,
          requestStatus:item.requestStatus,
          counselingStatus:item.counselingStatus,
          regCode:item.regCode,
        })
      }

      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const handleRowClick = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    window.location.href = `/user/${row.userId}/consult`;
  };

  // rowEvents 객체 정의
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // handleRowClick(e, row, rowIndex);
    },
  };

  const handlename = (e) => {
    setuserName(e.target.value);
  };
  const handlephone = (e) => {
    setuserPhone(e.target.value);
  };
  const handleCounselor = (e) => {
    setCounselor(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  useEffect(() => {
    setManagerRole(localStorage.getItem("loginUserType") === "manager");
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>상담회원 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    // columns={managerRole ? columns2 : columns}
                    columns={columns2}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            상담회원 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={username}
                                          onChange={handlename}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>전화번호</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="전화번호를 입력해 주세요."
                                          aria-label="전화번호를 입력해 주세요."
                                          value={phone}
                                          onChange={handlephone}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                {managerRole && false ? null:(
                                  <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>매니저</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="counselor_genderLabel"
                                          className="noborder form-select h-100"
                                          value={counselor}
                                          onChange={(e) =>
                                            setCounselor(e.target.value)
                                          }
                                          style={{
                                            maxWidth: "9rem",
                                          }}
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                )}
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  rowEvents={rowEvents} // rowEvents 속성 추가
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsultMemberList;
