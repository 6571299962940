import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMemberListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useHistory, Link } from "react-router-dom";

import { adminUserPut, requestDelete, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import axios from "axios";
//Import Breadcrumb
import "../Tables/datatables.scss";
import "../Consult/consult.scss";
import MemberListSearch from "./Modal/MemberListSearch";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import MemberImgModal from "./Modal/MemberImgModal";
import { ReactComponent as Minus } from "../../assets/icon/svgs/solid/minus.svg";
import { ReactComponent as Plus } from "../../assets/icon/svgs/solid/plus.svg";
import { first } from "lodash";
//lch1721 현재 회원 목록 페이지 
function MemberListcopy3(props) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [values, setValues] = useState({});
  const [searchModal, setSearchModal] = useState(false);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [username, setuserName] = useState("");
  const [counselor, setCounselor] = useState("");
  const [userMarriageFee, setUserMarriageFee] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [birthday, setBirthday] = useState("");
  const [sort, setSort] = useState("");
  const [gender, setGender] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [minHeight, setMinHeight] = useState("");
  const [maxHeight, setMaxHeight] = useState("");
  const [minYearIncome, setMinYearIncome] = useState("");
  const [maxYearIncome, setMaxYearIncome] = useState("");
  const [minFinalStage, setMinFinalStage] = useState("");
  const [maxFinalStage, setMaxFinalStage] = useState("");
  const [minMovableAsset, setMinMovableAsset] = useState("");
  const [maxMovableAsset, setMaxMovableAsset] = useState("");
  const [minImmovableAsset, setMinImmovableAsset] = useState("");
  const [maxImmovableAsset, setMaxImmovableAsset] = useState("");
  const [minParentAsset, setMinParentAsset] = useState("");
  const [maxParentAsset, setMaxParentAsset] = useState("");
  const [minParentImmovableAmount, setMinParentImmovableAmount] = useState("");
  const [maxParentImmovableAmount, setMaxParentImmovableAmount] = useState("");
  const [home, setHome] = useState("");
  const [minAppearanceScore, setMinAppearanceScore] = useState("");
  const [maxAppearanceScore, setMaxAppearanceScore] = useState("");
  const [minSpecsScore, setMinSpecsScore] = useState("");
  const [maxSpecsScore, setMaxSpecsScore] = useState("");
  const [minWealthScore, setMinWealthScore] = useState("");
  const [maxWealthScore, setMaxWealthScore] = useState("");
  const [smoke, setSmoke] = useState("");
  const [hoverImageSrc, setHoverImageSrc] = useState("");
  const [hoverImageStyle, setHoverImageStyle] = useState({ display: "none" });
  const [locationData, setLocationData] = useState([]);
  const [homeSido, setHomeSido] = useState("");
  const [homeRegionCode, setHomeRegionCode] = useState("");
  const [homeSidoList, setHomeSidoList] = useState([]);
  
  const [familyType, setFamilyType] = useState("");
  const [religion, setReligion] = useState([]);
  const [drink, setDrink] = useState("");
  const [marriage, setMarriage] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [managers, setManagers] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [searchOpen, setSearchOpen] = useState(true);
  const [marriagefeedata, setmarriagefeeData] = useState(null);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const history = useHistory();
  const { list, updateList, updateActiveNum } = useMemberListStore();
  const inputRefs = useRef({});

  const callApi = async () => {
    const marriagefeeresponse = await requestGet(
      `/api/product/marriagefee/list`
    );
    setmarriagefeeData(marriagefeeresponse?.data);
  };

  useEffect(() => {
    callManagerApi();
    callApi();
  }, []);
  
  const excelDown = async () => {
    try {
      const params = new URLSearchParams();
      const token = localStorage.getItem("merriageAgency-access");
      let headers = {};
      if (token) {
        headers = {
          "x-access-token": token,
        };
      }
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }

      axios({
        url: `${process.env.REACT_APP_API_HOST}/api/member/list/excel?${params}`,
        method: "GET",
        responseType: "blob",
        headers,
      }).then((response) => {
        const fileObjectUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = fileObjectUrl;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileObjectUrl);
      });
    } catch (error) {
      alert("Request failed.");
    }
  };


  const handleDivClick = (keyProp) => {
    console.log(inputRefs.current[keyProp]);
    if (inputRefs.current[keyProp]) {
      inputRefs.current[keyProp].focus();
      const event = new MouseEvent("mousedown", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      inputRefs.current[keyProp].dispatchEvent(event);
    }
  };

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const callLocation = async () => {
    try {
      const params = new URLSearchParams();
      params.append("search", true);
      const response = await requestGet(`/api/region/list/json?${params}`);
      setLocationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    callManagerApi();
    callLocation();

  }, []);



  const handleMouseEnter = (e, src) => {
    if (window.innerWidth > 768) {
      setHoverImageSrc(src);
      setHoverImageStyle({
        display: "block",
        position: "absolute",
        left: `${e.pageX + 20}px`,
        top: `${e.pageY}px`,
        width: "200px",
        // height: "200px",
        border: "1px solid #ccc",
        zIndex: 1000,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (window.innerWidth > 768) {
      setHoverImageStyle((prevState) => ({
        ...prevState,
        left: `${e.pageX + 20}px`,
        top: `${e.pageY}px`,
      }));
    }
  };

  const handleMouseLeave = () => {
    setHoverImageStyle({ display: "none" });
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "image",
      text: "사진",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        if (cellContent) {
          return (
            <div onClick={(e) => stopev(e)}>
              <Link
                to={`/image?src=${encodeURIComponent(cellContent)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="image-container"
                  onMouseEnter={(e) => handleMouseEnter(e, cellContent)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={cellContent}
                    alt="Thumbnail"
                    className="thumbnail"
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
              </Link>
            </div>
          );
        }
      },
    },
    {
      dataField: "name",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "phone",
      text: "전화번호",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "recommendCode",
      text: "추천코드",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "birthday",
      text: "생년월일",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "received",
      text: "받은 호감",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "sent",
      text: "보낸 호감",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "likeCoupon",
      text: "횟수제",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        let likeCoupon=row.finalLikeCoupon+row.finalFreeLikeCoupon;
        let colorClass='';
        if (likeCoupon<=2 ){
          colorClass='text-danger'
        }
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
        {likeCoupon>0?<span className={colorClass} >{likeCoupon}</span> :<span></span>}
          </a>
        );
      },
    },
    {
      dataField: "subscription",
      text: "기간제",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {

        let colorClass='';
        if (row.days<=30){
          colorClass='text-danger'
        }
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {row.hasSubscription?<div className={colorClass}>{row.days}</div> :<div></div>
            }
        
          </a>
        );
      },
    },
    {
      dataField: "dDay",
      text: "D-day",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "regDate",
      text: "가입일시",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "statusStr",
      text: "상태",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.id}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "actionDeny",
      text: "거절",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleRejectClick(e, row, rowIndex)}
            className="btn btn-danger btn-sm"
          >
            거절
          </button>
        );
      },
    },
    {
      dataField: "actionDelete",
      text: "삭제",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleWidthrawalClick(e, row, rowIndex)}
            className="btn btn-dark btn-sm"
          >
            삭제
          </button>
        );
      },
    },
  ];

  const defaultSorted = [];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length,
    custom: true,
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            if(key === "homeSidoList"){
              if (list[key].length > 0) {
                params.append(key, list[key]);
              }
            }
            else if (key === "religion") {
              if (list[key].length > 0) {
                params.append(key, list[key]);
              }
            } else if (
              key === "minYearIncome" ||
              key === "maxYearIncome" ||
              key === "minMovableAsset" ||
              key === "maxMovableAsset" ||
              key === "minImmovableAsset" ||
              key === "maxImmovableAsset" ||
              key === "minParentAsset" ||
              key === "maxParentAsset"
            ) {
              params.append(key, Number(list[key].replace(/\D/g, "")));
            } else {
              params.append(key, list[key]);
            }
          }
        }
      }
      const memberResponse = await requestGet(`/api/member/list_test?${params}`);
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setuserName(list.name);
    setActiveNum(list.page);
    setCounselor(list.counselor);
    setUserMarriageFee(list.userMarriageFee);
    setStatus(list.status);
    setBirthday(list.birthday);
    setPhone(list.phone);
    setSort(list.sort);
    setGender(list.gender);
    setMinAge(list.minAge);
    setMaxAge(list.maxAge);
    setMinHeight(list.minHeight);
    setMaxHeight(list.maxHeight);
    setMinYearIncome(list.minYearIncome);
    setMaxYearIncome(list.maxYearIncome);
    setMinFinalStage(list.minFinalStage);
    setMaxFinalStage(list.maxFinalStage);
    setMinMovableAsset(list.minMovableAsset);
    setMaxMovableAsset(list.maxMovableAsset);
    setMinImmovableAsset(list.minImmovableAsset);
    setMaxImmovableAsset(list.maxImmovableAsset);
    setMinParentAsset(list.minParentAsset);
    setMaxParentAsset(list.maxParentAsset);
    setMinParentImmovableAmount(list.minParentImmovableAmount);
    setMaxParentImmovableAmount(list.maxParentImmovableAmount);
    setHome(list.home);
    setMinAppearanceScore(list.minAppearanceScore);
    setMaxAppearanceScore(list.maxAppearanceScore);
    setMinSpecsScore(list.minSpecsScore);
    setMaxSpecsScore(list.maxSpecsScore);
    setMinWealthScore(list.minWealthScore);
    setMaxWealthScore(list.maxWealthScore);
    setSmoke(list.smoke);
    setHomeSido(list.homeSido);
    setHomeRegionCode(list.homeRegionCode);
    setFamilyType(list.familyType);
    setReligion(list.religion);
    setDrink(list.drink);
    setFirstLoad(list.firstLoad);
    setMarriage(list.marriage);
    setHomeSidoList(list.homeSidoList);
    if(!list.firstLoad){
      callInfo();
    }
    
  }, [callInfo, list]);

  const gohistory = () => {
    setFirstLoad(false);
    updateList(
      username,
      1,
      counselor,
      userMarriageFee,
      status,
      birthday,
      phone,
      sort,
      gender,
      minAge,
      maxAge,
      minHeight,
      maxHeight,
      minYearIncome,
      maxYearIncome,
      minFinalStage,
      maxFinalStage,
      minMovableAsset,
      maxMovableAsset,
      minImmovableAsset,
      maxImmovableAsset,
      minParentAsset,
      maxParentAsset,
      minParentImmovableAmount,
      maxParentImmovableAmount,
      home,
      minAppearanceScore,
      maxAppearanceScore,
      minSpecsScore,
      maxSpecsScore,
      minWealthScore,
      maxWealthScore,
      smoke,
      homeSido,
      homeRegionCode,
      familyType,
      religion,
      drink,
      marriage,
      homeSidoList,
      false
    );
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          birthday: item.birthday === "Invalid date" ? "" : item.birthday,
          gender: item.gender,
          phone: item.phone,
          dDay: item.dDay,
          recommendCode: item.recommendCode,
          regDate: item.regDate,
          received: item.received,
          grade: item.grade,
          finalLikeCoupon: item.finalLikeCoupon,
          finalFreeLikeCoupon: item.finalFreeLikeCoupon,
          purchaseLikeCoupon:item.purchaseLikeCoupon,
          days: item.days,
          hasSubscription: item.hasSubscription,
          sent: item.sent,
          isSleep: item.isSleep ? "휴면" : "정상",
          image: item.image,
          statusStr:item.statusStr
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const stopev = (e) => {
    e.stopPropagation();
  };

  const handleRowClick = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    window.location.href = `/member/detail/${row.id}`;
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(e, row, rowIndex);
    },
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handlename = (e) => {
    setuserName(e.target.value);
  };

  const handleCounselor = (e) => {
    setCounselor(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  const handleRejectClick = async (e, row, rowIndex) => {
    e.stopPropagation();
    if (window.confirm("거절 하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await adminUserPut(`/api/member/reject`, body);
        alert("거절 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert("거절 실패 하였습니다.");
      }
    }
  };
  const handleWidthrawalClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await requestDelete(`/api/member/${row.id}`);
        alert("삭제 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error?.data?.message);
      }
    }
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, searchOpen]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>회원 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>회원 목록</h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <span>조회 조건</span>
                                  {/* {searchOpen ? (
                                    <Minus
                                      width={20}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setSearchOpen(false)}
                                    />
                                  ) : (
                                    <Plus
                                      width={20}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setSearchOpen(true)}
                                    />
                                  )} */}
                                </Col>
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>이름</span>
                                      </Col>
                                      <Col className="m-0 p-0">
                                        <div className="h-100">
                                          <input
                                            type="text"
                                            className="js-input-mask form-control customSearchInput h-100"
                                            name="name"
                                            id="nameLabel"
                                            placeholder="이름을 입력해 주세요."
                                            aria-label="이름을 입력해 주세요."
                                            value={username}
                                            onChange={handlename}
                                            onKeyDown={handleKeyPress}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>전화번호</span>
                                      </Col>
                                      <Col className="m-0 p-0">
                                        <div className="h-100">
                                          <input
                                            type="text"
                                            className="js-input-mask form-control customSearchInput h-100"
                                            name="name"
                                            id="nameLabel"
                                            placeholder="전화번호를 입력해 주세요."
                                            aria-label="전화번호를 입력해 주세요."
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                            onKeyDown={handleKeyPress}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>생년월일</span>
                                      </Col>
                                      <Col className="m-0 p-0">
                                        <div className="h-100">
                                          <input
                                            type="text"
                                            className="js-input-mask form-control customSearchInput h-100"
                                            name="name"
                                            id="nameLabel"
                                            placeholder="생년월일을 입력해 주세요."
                                            aria-label="생년월일을 입력해 주세요."
                                            value={birthday}
                                            onChange={(e) =>
                                              setBirthday(e.target.value)
                                            }
                                            onKeyDown={handleKeyPress}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>성별</span>
                                      </Col>
                                      <Col
                                        className="m-0 p-0"
                                        style={{
                                          borderBottom: "1px solid #ced4da",
                                        }}
                                      >
                                        <div
                                          className="h-100"
                                          onClick={() =>
                                            handleDivClick("gender")
                                          }
                                        >
                                          <select
                                            id="counselor_genderLabel"
                                            name="gender"
                                            ref={(el) =>
                                              (inputRefs.current["gender"] = el)
                                            }
                                            className="noborder form-select h-100"
                                            value={gender}
                                            onChange={(e) =>
                                              setGender(e.target.value)
                                            }
                                            style={{
                                              maxWidth: "9rem",
                                            }}
                                          >
                                            <option value="">전체</option>
                                            <option value="남성">남성</option>
                                            <option value="여성">여성</option>
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>상태</span>
                                      </Col>
                                      <Col
                                        className="m-0 p-0"
                                        style={{
                                          borderBottom: "1px solid #ced4da",
                                        }}
                                      >
                                        <div className="h-100">
                                          <select
                                            id="counselor_genderLabel"
                                            className="noborder form-select h-100"
                                            value={status}
                                            onChange={(e) =>
                                              setStatus(e.target.value)
                                            }
                                            style={{
                                              maxWidth: "9rem",
                                            }}
                                          >
                                            <option value="">전체</option>
                                            <option value="active">정상</option>
                                            <option value="sleep">휴면</option>
                                            <option value="sleep2">기간제 차감 휴면</option>
                                            <option value="matched">
                                              교제중
                                            </option>
                                            <option value="expired">
                                              만료
                                            </option>
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>매니저</span>
                                      </Col>
                                      <Col
                                        className="m-0 p-0"
                                        style={{
                                          borderBottom: "1px solid #ced4da",
                                        }}
                                      >
                                        <div className="h-100">
                                          <select
                                            id="counselor_genderLabel"
                                            className="noborder form-select h-100"
                                            value={counselor}
                                            onChange={(e) =>
                                              setCounselor(e.target.value)
                                            }
                                            style={{
                                              maxWidth: "9rem",
                                            }}
                                          >
                                            <option value="">전체</option>
                                            {managers?.map((item, i) => {
                                              return (
                                                <option
                                                  value={item.name}
                                                  key={i}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>성혼비</span>
                                      </Col>
                                      <Col
                                        className="m-0 p-0"
                                        style={{
                                          borderBottom: "1px solid #ced4da",
                                        }}
                                      >
                                        <div className="h-100">
                                          <select
                                            id="counselor_genderLabel"
                                            className="noborder form-select h-100"
                                            value={userMarriageFee}
                                            onChange={(e) =>
                                              setUserMarriageFee(e.target.value)
                                            }
                                            style={{
                                              maxWidth: "9rem",
                                            }}
                                          >
                                            <option value="">전체</option>
                                            {marriagefeedata?.map((item, i) => {
                                              return (
                                                <option value={item.id} key={i}>
                                                  {item.name}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                {searchOpen ? (
                                  <Col
                                    lg={12}
                                    className="d-flex p-0 flex-column"
                                  >
                                    <Row className="w-100 m-0">
                                      <Col
                                        lg={2}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding:
                                            "0.47rem 0px 0.75rem 0.47rem",
                                          backgroundColor: "#ccc",
                                          fontWeight: 700,
                                          borderTop: "0.3px solid #fff",
                                          borderBottom: "0.3px solid #fff",
                                        }}
                                      >
                                        <span>정렬</span>
                                      </Col>
                                      <Col className="m-0 p-0">
                                        <div className="h-100">
                                          <select
                                            id="counselor_genderLabel"
                                            className="noborder form-select h-100"
                                            value={sort}
                                            onChange={(e) =>
                                              setSort(e.target.value)
                                            }
                                            style={{
                                              maxWidth: "9rem",
                                            }}
                                          >
                                            <option value="agreeDate">승인일시 순</option>
                                            <option value="sent">
                                              호감 보낸 순
                                            </option>
                                            <option value="recieved">
                                              호감 받은 순
                                            </option>
                                            <option value="grade">등급 순</option>
                                            <option value="createdAt">가입일시 순</option>
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ) : null}
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      style={{
                                        borderRadius: "0px",
                                        backgroundColor: "#59c467",
                                      }}
                                      onClick={() =>
                                        setSearchModal(!searchModal)
                                      }
                                    >
                                      상세조건
                                    </Button>
                                  </div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-50"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                    <Button
                                      className="w-50"
                                      color="warning"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => excelDown()}
                                    >
                                      다운로드
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  rowEvents={rowEvents} // rowEvents 속성 추가
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {searchModal ? (
        <MemberListSearch
          setModal={setSearchModal}
          updateList={updateList}
          username={username}
          counselor={counselor}
          status={status}
          birthday={birthday}
          phone={phone}
          sort={sort}
          gender={gender}
          values={values}
          handleChange={handleChange}
          minAge={minAge}
          maxAge={maxAge}
          minHeight={minHeight}
          maxHeight={maxHeight}
          minYearIncome={minYearIncome}
          maxYearIncome={maxYearIncome}
          minFinalStage={minFinalStage}
          maxFinalStage={maxFinalStage}
          minMovableAsset={minMovableAsset}
          maxMovableAsset={maxMovableAsset}
          minImmovableAsset={minImmovableAsset}
          maxImmovableAsset={maxImmovableAsset}
          minParentAsset={minParentAsset}
          maxParentAsset={maxParentAsset}
          home={home}
          minAppearanceScore={minAppearanceScore}
          maxAppearanceScore={maxAppearanceScore}
          minSpecsScore={minSpecsScore}
          maxSpecsScore={maxSpecsScore}
          minWealthScore={minWealthScore}
          maxWealthScore={maxWealthScore}
          smoke={smoke}
          setMinAge={setMinAge}
          setMaxAge={setMaxAge}
          setMinHeight={setMinHeight}
          setMaxHeight={setMaxHeight}
          setMinYearIncome={setMinYearIncome}
          setMaxYearIncome={setMaxYearIncome}
          setMinFinalStage={setMinFinalStage}
          setMaxFinalStage={setMaxFinalStage}
          setMinMovableAsset={setMinMovableAsset}
          setMaxMovableAsset={setMaxMovableAsset}
          setMinImmovableAsset={setMinImmovableAsset}
          setMaxImmovableAsset={setMaxImmovableAsset}
          setMinParentAsset={setMinParentAsset}
          setMaxParentAsset={setMaxParentAsset}
          setMinParentImmovableAmount={setMinParentImmovableAmount}
          setMaxParentImmovableAmount={setMaxParentImmovableAmount}
          setHome={setHome}
          setMinAppearanceScore={setMinAppearanceScore}
          setMaxAppearanceScore={setMaxAppearanceScore}
          setMinSpecsScore={setMinSpecsScore}
          setMaxSpecsScore={setMaxSpecsScore}
          setMinWealthScore={setMinWealthScore}
          setMaxWealthScore={setMaxWealthScore}
          setSmoke={setSmoke}
          locationData={locationData}
          homeSido={homeSido}
          setHomeSido={setHomeSido}
          homeRegionCode={homeRegionCode}
          setHomeRegionCode={setHomeRegionCode}
          familyType={familyType}
          setFamilyType={setFamilyType}
          religion={religion}
          setReligion={setReligion}
          drink={drink}
          setDrink={setDrink}
          setMarriage={setMarriage}
          marriage={marriage}
          homeSidoList={homeSidoList} 
          setHomeSidoList={setHomeSidoList}
        />
      ) : null}
      <div style={hoverImageStyle}>
        <img
          src={hoverImageSrc}
          alt="Hover"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </React.Fragment>
  );
}

export default MemberListcopy3;
