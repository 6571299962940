const MemberOption = [
  { tag: "기본 정보", userCode: true },
  // { name: "메세지 알림톡", key: "messageAlarmtalk", type: "messageAlarmtalk" },
  {
    name: "이름",
    key: "name",
    type: "text",
  },
  {
    name: "상태",
    key: "status",
    type: "select",
    value: ["active", "sleep", "sleep2", "matched",'refund','refund-pending','expired'],
  },
  {
    name: "상대 유저코드",
    key: "matchedUser",
    type: "text",
  },
  {
    name: "전화번호",
    key: "phone",
    type: "phone",
  },
  {
    name: "내부 코멘트",
    key: "secretComment",
    type: "textarea",
  },
  {
    name: "이메일 주소",
    key: "email",
    type: "email",
  },
  {
    name: "소개하기",
    key: "targetCode",
    type: "intro",
  },
  {
    name: "매니저",
    key: "counselor",
    type: "select",
  },
  {
    name: "회원가입비",
    key: "joinFee",
    type: "select",
  },
  {
    name: "성혼사례비",
    key: "userMarriageFee",
    type: "select",
  },
  {
    name: "프로필 교정 여부",
    key: "profileConfirm",
    type: "radio",
    value: ["O", "X"],
  },
  {
    name: "추천코드",
    key: "recommendCode",
    type: "text",
  },
  {
    name: "가입 경로",
    key: "joinFrom",
    type: "text",
  },
  // {
  //   name: "인증사진",
  //   key: "acceptImage",
  //   type: "인증사진",
  // },
  {
    name: "외부 이미지",
    key: "outImage",
    type: "외부 이미지",
  },
  {
    name: "내부 이미지",
    key: "inImage",
    type: "내부 이미지",
  },
  {
    name: "동영상 첨부",
    key: "videos",
    type: "동영상 첨부",
  },
  {
    name: "최종 등급",
    key: "totalScore",
    type: "select",
    value: ["A", "B", "C", "D"],
  },
  {
    name: "외모 등급",
    key: "appearanceScore",
    type: "select",
    value: ["A", "B", "C", "D"],
  },
  {
    name: "스펙 등급",
    key: "specsScore",
    type: "select",
    value: ["A", "B", "C", "D"],
  },
  {
    name: "경제력 등급",
    key: "wealthScore",
    type: "select",
    value: ["A", "B", "C", "D"],
  },
  {
    name: "매니저 코멘트",
    key: "comment",
    type: "textarea",
  },
  {
    name: "성별",
    key: "gender",
    type: "radio",
    value: ["남성", "여성"],
  },
  {
    name: "생년월일",
    key: "birth",
    type: "date",
  },
  {
    name: "MBTI",
    key: "mbti",
    type: "text",
  },
  {
    name: "키",
    key: "height",
    type: "number",
  },
  {
    name: "체중",
    key: "weight",
    type: "number",
  },
  {
    name: "결혼여부",
    key: "ismarry",
    type: "radio",
    value: ["미혼", "사실혼", "무출산 재혼", "출산 재혼"],
  },
  {
    name: "거주지역",
    key: "customAdress",
    type: "location",
  },
  {
    name: "본가",
    key: "tureHome",
    type: "location",
  },
  {
    name: "주량",
    key: "drink",
    type: "radio",
    value: [
      "안마셔요",
      "어쩔 수 없을 때만 마셔요",
      "가끔마셔요",
      "어느정도 즐겨요",
      "즐기는 편이예요",
    ],
  },
  {
    name: "흡연",
    key: "smoke",
    type: "radio",
    value: ["비흡연", "흡연(연초)", "흡연(전자담배)", "금연예정", "금연중"],
  },
  {
    name: "혈액형",
    key: "bloodType",
    type: "radio",
    value: ["A", "B", "AB", "O"],
  },
  {
    name: "문신",
    key: "tatoo",
    type: "radio",
    value: ["없음", "작은거1개", "작은거2개 또는 큰문신 1개이상"],
  },
  {
    name: "운동",
    key: "sport",
    type: "radio",
    value: ["안함", "주1~2회", "주3~4회", "매일"],
  },
  {
    name: "종교",
    key: "religion",
    type: "etc",
    value: ["무교", "천주교", "불교", "기독교"],
  },
  {
    name: "신체 특징",
    key: "bodyCharacter",
    type: "text",
  },
  {
    name: "병역여부",
    key: "military",
    type: "radio",
    value: ["예비역", "면제"],
  },
  {
    name: "본인 외모점수",
    key: "myface",
    type: "radio",
    value: [
      "못생김",
      "평균이하",
      "약간 평균 이하",
      "평범함",
      "호감형",
      "약간 매력적",
      "매력적",
      "매우 매력적",
    ],
  },
  { tag: "학력" },
  {
    name: "최종학력",
    key: "finaledu",
    type: "radio",
    value: [
      "고졸",
      "전문대 재학",
      "전문대 수료",
      "전문대 졸업",
      "대학 재학",
      "대학 중퇴",
      "대학 수료",
      "대학 졸업",
      "대학원 재학",
      "대학원졸업",
      "박사 과정",
      "박사 취득",
    ],
  },
  {
    name: "대학명(학사)",
    key: "hakname",
    type: "text",
  },
  {
    name: "졸업년도(학사)",
    key: "hakenddate",
    type: "number",
  },
  {
    name: "전공(학과)",
    key: "hakskill",
    type: "text",
  },
  {
    name: "대학원명(석,박사)",
    key: "sukname",
    type: "text",
  },
  {
    name: "졸업년도(석,박사)",
    key: "sukenddate",
    type: "number",
  },
  {
    name: "전공(석,박사)",
    key: "sukskill",
    type: "text",
  },
  { tag: "직업" },
  {
    name: "직업",
    key: "jobname",
    type: "text",
  },
  {
    name: "직업 분류 ",
    key: "career",
    type: "etc",
    value: [
      "대기업",
      "중견기업",
      "중소기업",
      "스타트업",
      "공기업",
      "공공기관",
      "외국계기업",
      "미용",
      "미디어",
      "예술",
      "레저/스포츠",
      "외식/식음료",
      "공인중개사",
      "문과전문직",
      "이과전문직",
      "간호사",
      "의료직",
      "유치원교사",
      "초등학교교사",
      "중,고등학교교사",
      "학원강사",
      "교육직",
      "공무원",
      "경찰",
      "소방관",
      "직업군인",
      "사업가",
      "자영업",
      "금융직",
      "프리랜서",
    ],
  },
  {
    name: "기타직업",
    key: "etcjobname",
    type: "text",
  },
  {
    name: "고용형태",
    key: "jobstatus",
    type: "etc",
    value: [
      "정규직",
      "계약직",
      "프리랜서",
      "가업승계",
      "개인사업자",
      "법인사업자",
    ],
  },
  {
    name: "근무형태",
    key: "workstatus",
    type: "radio",
    value: ["일반근무", "교대근무", "재택근무", "자율근무", "파트타임"],
  },
  {
    name: "연봉(세전)",
    key: "yearmoney",
    type: "money",
  },
  {
    name: "직장명",
    key: "comname",
    type: "text",
  },
  {
    name: "직급",
    key: "level",
    type: "text",
  },
  {
    name: "회사소재지",
    key: "jobaddres",
    type: "location",
  },
  { tag: "자산정보" },
  {
    name: "유동자산",
    key: "movemoney",
    type: "money",
  },
  {
    name: "비유동자산(부동산 포함)",
    key: "nomovemoney",
    type: "money",
  },
  {
    name: "비유동자산(부동산 포함) 소유여부",
    key: "hasnomovemoney",
    type: "radio",
    value: ["없음", "있음"],
  },
  {
    name: "혼인 시 경제적 지원",
    key: "merrymoney",
    type: "radio",
    value: ["없음", "있음"],
  },
  {
    name: "차량 유무",
    key: "hascar",
    type: "radio",
    value: ["없음", "자차", "개인렌트/리스", "법인렌트/리스"],
  },
  {
    name: "차량 제조사",
    key: "makecarname",
    type: "text",
  },
  {
    name: "차량 모델명",
    key: "carname",
    type: "text",
  },
  { tag: "가족관계" },
  {
    name: "가족형태",
    key: "familyinfo",
    type: "radio",
    value: ["일반가정", "재혼가정", "다문화가정", "장애인가정", "이혼가정"],
  },
  {
    name: "형제관계",
    key: "brothers",
    type: "brothers",
  },
  {
    name: "아버지 직업",
    key: "fajob",
    type: "text",
  },
  {
    name: "어머니 직업",
    key: "mojob",
    type: "text",
  },
  {
    name: "부모님 자산",
    key: "pamoney",
    type: "money",
  },
  {
    name: "부모님과의 동거 여부",
    key: "livealone",
    type: "radio",
    value: ["동거중", "동거안함"],
  },
  {
    name: "부모님의 동거 여부",
    key: "pawithlive",
    type: "radio",
    value: ["동거중", "동거안함"],
  },
  {
    name: "노후 준비 여부",
    key: "paalone",
    type: "radio",
    value: ["가능", "불가"],
  },
  {
    name: "부모님 이혼여부",
    key: "paend",
    type: "radio",
    value: ["해당함", "해당없음"],
  },
  {
    name: "부모님 작고여부",
    key: "pasad",
    type: "radio",
    value: ["양친생존", "편부모", "양친작고"],
  },
  { tag: "재혼 정보" },
  {
    name: "결혼기간 시작날짜",
    key: "marriageStartDate",
    type: "date",
  },
  {
    name: "결혼기간 끝날짜",
    key: "marriageEndDate",
    type: "date",
  },
  {
    name: "이혼 사유",
    key: "reason",
    type: "text",
  },
  {
    name: "양육 유무",
    key: "care",
    type: "radio",
    value: ["양육중", "양육안함"],
  },
  {
    name: "자녀수",
    key: "childrenNumber",
    type: "number",
  },
  { tag: "본인 상세정보" },
  {
    name: "관심사/취미",
    key: "myhobby",
    type: "text",
  },
  {
    name: "성격",
    key: "mystyle",
    type: "textarea",
  },
  { tag: "이상형 우선순위" },
  {
    name: "최소 나이(연나이 기준)",
    key: "ideal_birthYearMin",
    type: "number",
  },
  {
    name: "최대 나이(연나이 기준)",
    key: "ideal_birthYearMax",
    type: "number",
  },
  {
    name: "외모",
    key: "ideal_appearance",
    type: "etc",
    value: [
      "상관없음",
      "평범함",
      "호감형",
      "약간 매력적",
      "매력적",
      "매우 매력적",
    ],
  },
  {
    name: "최소 키",
    key: "ideal_heightMin",
    type: "number",
  },
  {
    name: "최대 키",
    key: "ideal_heightMax",
    type: "number",
  },
  {
    name: "최소 학력",
    key: "ideal_education",
    type: "etc",
    value: [
      "상관없음",
      "스카이",
      "인서울4년제",
      "지방국공립",
      "지방4년제",
      "해외대학",
      "전문대",
    ],
  },
  {
    name: "직업분류",
    key: "ideal_industry",
    type: "check",
  },
  {
    name: "고용형태",
    key: "hateEmploymentType",
    type: "checks",
    value: [
      "상관없음",
      "프리랜서",
      "법인사업자",
      "개인사업자",
      "가업승계",
      "정규직",
      "위촉직",
      "계약직",
    ],
  },
  {
    name: "소득",
    key: "ideal_income",
    type: "radio",
    value: [
      "상관없음",
      "4천만원 미만",
      "4천만원 이상 6천만원 미만",
      "6천만원 이상 8천만원 미만",
      "8천만원 이상 1억원 미만",
      "1억원 이상 3억원미만",
      "3억원 이상",
    ],
  },
  {
    name: "본인자산",
    key: "ideal_personalAsset",
    type: "radio",
    value: [
      "상관없음",
      "0~5천만원",
      "5천~1억",
      "1억~3억",
      "3억~5억",
      "5억~10억",
      "10억~30억",
      "30억 이상",
    ],
  },
  {
    name: "부동산 자산유무",
    key: "ideal_hasImmovable",
    type: "etc",
    value: ["상관없음", "있음"],
  },
  {
    name: "부모님 자산",
    key: "ideal_parentalAsset",
    type: "radio",
    value: [
      "상관없음",
      "1~5억 사이",
      "5~10억 사이",
      "10~30억 사이",
      "30~50억 사이",
      "50~100억 사이",
      "100억 이상",
    ],
  },
  {
    name: "종교",
    key: "ideal_religion",
    type: "etc",
    value: ["무교", "천주교", "불교", "기독교", "상관없음"],
  },
  {
    name: "만남 가능 지역",
    key: "ideal_allowRegion",
    type: "checks",
    value: [
      "전국",
      "서울",
      "인천",
      "경기 남부",
      "경기 북부",
      "부산/경남",
      "대구/경북",
      "전라도",
      "세종/대전/충청",
      "강원",
      "제주",
    ],
  },
  {
    name: "성격",
    key: "ideal_caracter",
    type: "text",
  },
  {
    name: "결혼여부",
    key: "hateMarriageHistory",
    type: "radio",
    value: ["미혼", "사실혼", "무출산 재혼", "출산 재혼"],
  },
  {
    name: "딩크 여부",
    key: "isDink",
    type: "radio",
    value: ["예", "아니요"],
  },
  {
    name: "흡연",
    key: "hateSmokeType",
    type: "etc",
    value: ["비흡연", "흡연(연초)", "흡연(전자담배)", "금연예정"],
  },
  {
    name: "문신여부",
    key: "hateTatoo",
    type: "etc",
    value: ["상관없음", "작은거 1개", "작은거 2개 또는 큰문신 1개이상", "없음"],
  },
  {
    name: "부모님 정보",
    key: "hateParentStatus",
    type: "radio",
    value: ["상관없음", "일반가정", "이혼함", "재혼함", "편부모", "양친작고"],
  },
  {
    name: "차량유무",
    key: "hateHasCarType",
    type: "etc",
    value: ["상관없음", "차량없음", "차량있음"],
  },
  {
    name: "우선순위 1순위",
    key: "priority1",
    type: "select",
    value: [
      "얼굴",
      "피지컬",
      "나이",
      "학력",
      "자산 및 집안",
      "직업 및 소득",
      "매력(성격)",
      "종교",
      "거리",
    ],
  },
  {
    name: "우선순위 2순위",
    key: "priority2",
    type: "select",
    value: [
      "얼굴",
      "피지컬",
      "나이",
      "학력",
      "자산 및 집안",
      "직업 및 소득",
      "매력(성격)",
      "종교",
      "거리",
    ],
  },
  {
    name: "우선순위 3순위",
    key: "priority3",
    type: "select",
    value: [
      "얼굴",
      "피지컬",
      "나이",
      "학력",
      "자산 및 집안",
      "직업 및 소득",
      "매력(성격)",
      "종교",
      "거리",
    ],
  },
  { tag: "이상형 기피사항" },
  {
    name: "직업분류",
    key: "hateIndustry",
    type: "text",
  },
  {
    name: "관심사",
    key: "hateInterest",
    type: "text",
  },
  {
    name: "추가 기피사항",
    key: "idealHateDetail",
    type: "textarea",
  },
  { tag: "이상형 상세정보" },
  {
    name: "이상형 상세정보",
    key: "hopeStyle",
    type: "textarea",
  },
];
export default MemberOption;
