import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";

import {
  Button,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
}

export default function ImageList(props) {
  const imageType = props.match.path.indexOf('mainImage') !== -1 ? 1 : 2;
  const [listData, setListData] = useState([]);
  const [pageIdx, setPageIdx] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  async function getListData() {
    const params = new URLSearchParams({
      imageType: imageType,
      pageIdx: pageIdx,
    });

    const token = localStorage.getItem("merriageAgency-access");
    console.log(process.env.APIURL)

    const data = await fetch(`${process.env.REACT_APP_APIURL}/api/image/list?${params}`, {
      headers: {
        'x-access-token': token,
        withCredentials: true,
      },
      method: 'GET',
    });
    const result = await data.json();
    setListData(result.data);
    setTotalPages(result.data.totalPage);
  }

  useEffect(() => {
    getListData();
  }, [pageIdx]);

  const handlePageChange = (page) => {
    setPageIdx(page);
  };

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>{imageType === 1 ? '메인 상단' : '파티'} 이미지</title>
        </MetaTags>
        <div className="row">
          <div className="col-12 col">
            <div className="card">
              <div className="card-body">
                <div><h2>{imageType === 1 ? '메인 상단' : '파티'} 이미지</h2></div>
                <div className="review_lst">
                  <table>
                    <thead>
                      <tr>
                        <th>이미지</th>
                        <th>모바일 이미지</th>
                        <th>출력순서</th>
                        <th>등록일</th>
                        <th>게시물 숨김</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData.data?.map((el, i) => (
                        <tr key={i} onClick={() => { window.location.href = `/${imageType === 1 ? 'main' : 'party'}Image/view/${el.no}`; }}>
                          <td><img src={`https://d3txsylzm2ga7z.cloudfront.net/${el.pc_src}`}/></td>
                          <td><img src={`https://d3txsylzm2ga7z.cloudfront.net/${el.m_src}`}/></td>
                          <td>{el.index}</td>
                          <td>{formatDate(el.createdAt)}</td>
                          <td>{el.view === 0 ? 'O' : ''}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
                <Button
                  color="primary"
                  className="me-2"
                  onClick={e => { window.location.href = `/${imageType === 1 ? 'main' : 'party'}Image/write`}}
                >
                  등록
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
