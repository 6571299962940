import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Consult
import ConsultRequest from "../pages/Consult/ConsultRequest";
import ConsultList from "../pages/Consult/ConsultListcopy2";
import ConsultRead from "../pages/Consult/ConsultRead";
import ConsultMemberList from "../pages/Consult/ConsultMemberListcopy";
import ConsultMemberListOld from "../pages/Consult/ConsultMemberListOld";
import RequestMemberList from "../pages/Consult/RequestMemberListcopy";
import User from "../pages/User/User";
import MemberDetail from "../pages/User/Member";
import ImgDetail from "../pages/User/ImgDetail";
import RegUser from "../pages/RegUser/User";
import Member from "../pages/Member/MemberListcopy3";
import MemberRefund from "../pages/Member/MemberRefund";
import DeleteMemberList from "../pages/Member/DeleteMemberListcopy";
import RegMember from "../pages/Member/RegMemberList";
import IntroMemberList from "../pages/Member/IntroMemberList";
import LikeMemberList from "../pages/Member/LikeMemberList";
import MatchListHistory from "../pages/Member/MatchListHistorycopy";
import MatchMessageHistory from "../pages/Member/MatchMessageHistorycopy";
import IncentiveHistory from "../pages/Member/IncentiveHistorycopy";
import IntroList from "../pages/Member/IntroListcopy";
import LikeList from "../pages/Member/LikeListcopy";
import LikeTooList from "../pages/Member/LikeTooListcopy";
import MemberJoinDetail from "../pages/Member/JoinDetail/User";

import DisabledSchedule from "../pages/Admin/DisabledSchedule";
import Schedule from "../pages/Admin/Schedule";
import TotalTable from "../pages/Admin/TotalTablecopy";


// Review
import ReviewList from "../pages/Review/ReviewList";
import ReviewWrite from "../pages/Review/ReviewWrite";
import ReviewView from "../pages/Review/ReviewView";

import ImageList from "../pages/Image/ImageList";
import ImageWrite from "../pages/Image/ImageWrite";
import ImageView from "../pages/Image/ImageView";


//Cash
import Cash from "../pages/Cash/Cashcopy";
import LikeHistory from "../pages/Cash/LikeHistorycopy";

import AlarmList from "../pages/Admin/AlarmList";
import LoginRedirectPage from "../pages/Authentication/LoginRedirectPage";

const userRoutes = [
  //default
  { path: "/dashboard", component: LoginRedirectPage },
  //Consult
  { path: "/consult/request", component: ConsultRequest },
  { path: "/consult/list", component: ConsultList },
  { path: "/consult/request/list", component: RequestMemberList },
  // { path: "/consult/member/list", component: ConsultMemberListOld },
  { path: "/consult/member/list", component: ConsultMemberList },
  { path: "/consult/read/:id", component: ConsultRead },
  { path: "/user/:id/:title", component: User },

  //Review
  { path: "/coupleReview/list", component: ReviewList},
  { path: "/coupleReview/write", component: ReviewWrite},
  { path: "/coupleReview/view/:id", component: ReviewView},
  { path: "/coupleReview/edit/:id", component: ReviewWrite},

  { path: "/partyReview/list", component: ReviewList},
  { path: "/partyReview/write", component: ReviewWrite},
  { path: "/partyReview/view/:id", component: ReviewView},
  { path: "/partyReview/edit/:id", component: ReviewWrite},

 //Image
 { path: "/mainImage/list", component: ImageList},
 { path: "/mainImage/write", component: ImageWrite},
 { path: "/mainImage/View/:id", component: ImageView},
 { path: "/mainImage/edit/:id", component: ImageWrite},


  //Member
  { path: "/member", component: Member },
  { path: "/member/refundexpired", component: MemberRefund },
  { path: "/member/delete/list", component: DeleteMemberList },
  { path: "/member/waiting", component: RegMember },
  { path: "/member/detail/:id", component: MemberDetail },
  { path: "/member/join/:id", component: MemberJoinDetail },
  { path: "/member/like/:id", component: LikeMemberList },
  { path: "/intro/list", component: IntroList },
  { path: "/like/list", component: LikeList },
  { path: "/incentive/list", component: IncentiveHistory },
  { path: "/like/match/list", component: LikeTooList },
  { path: "/like/match/user/list", component: MatchListHistory },
  // { path: "/like/match/message/list", component: MatchMessageHistory },

  //Cash
  { path: "/cash/history", component: Cash },
  { path: "/like/history", component: LikeHistory },

  //Admin
  { path: "/disabled/schedule", component: DisabledSchedule },
  { path: "/schedule", component: Schedule },
  { path: "/total/list", component: TotalTable },
  { path: "/alarm/list", component: AlarmList },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/image", component: ImgDetail },
  { path: "/reguser", component: RegUser },
];

export { userRoutes, authRoutes };
