import React, { useRef, useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import MetaTags from "react-meta-tags";
import {
  formPost,
} from "../../apis/request";

import {
  Button,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";

export default function ImageWrite(props) {
  const imageType = props.match.path.indexOf('mainImage') !== -1 ? 1 : 2;
  const writeComp = props.match.path.indexOf('write') !== -1 ? 1 : 0;
  const boardNo = props.match.params.id;

  const pcSrcRef = useRef(null);
  const mSrcRef = useRef(null);
  const indexRef = useRef(null);
  const [view, setView] = useState(1);
  const [viewData, setViewData] = useState(null);


  async function getData(){
    const token = localStorage.getItem("merriageAgency-access");
    const data = await fetch(`${process.env.REACT_APP_APIURL}/api/image/view?id=${boardNo}`, {
      headers: {
        'x-access-token': token,
        withCredentials: true,
      },
      method: 'GET',
    });
    const result = await data.json();
    setViewData(result.data.data[0]);
    setView(result.data.data[0].view);
  }


  useEffect(() => {
    if(writeComp === 0){
      getData();
    }
  }, []);

  async function onSbm(e){
    e.preventDefault();
    
    const indexValue = indexRef.current.value;
    const pcFiles = pcSrcRef.current.files;
    const mFiles = mSrcRef.current.files;

    
    if(pcFiles.length === 0){
      if(writeComp === 0 && viewData.pc_src !== ''){
      }else{
        alert('썸네일 이미지를 선택해주세요.');
        return false;
      }
    }

    const formData = new FormData();
    if (pcFiles.length > 0) {
      formData.append('pc_src', pcFiles[0]);
    }
    if (mFiles.length > 0) {
      formData.append('m_src', mFiles[0]);
    }
    formData.append('imageType', imageType);
    formData.append('view', view);
    formData.append('index', indexValue);

    if(writeComp === 0){
      formData.append('no', boardNo);
    }

    const token = localStorage.getItem("merriageAgency-access");

    const url = writeComp === 1 ? `${process.env.REACT_APP_APIURL}/api/image/write` : `${process.env.REACT_APP_APIURL}/api/image/edit`
    const data = await fetch(url, {
      headers: {
        'x-access-token': token,
        withCredentials: true,
      },
      method: 'POST',
      body: formData,
    });
    const result = await data.json();
    
    window.location.href = `/${imageType === 1 ? 'main' : 'party'}Image/list`;

  }
  async function imageDel(){
    if(window.confirm('선택된 데이터가 삭제됩니다.')){
      const token = localStorage.getItem("merriageAgency-access");
      const data = await fetch(`${process.env.REACT_APP_APIURL}/api/image/del`, {
      
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          withCredentials: true,
        },
        method: 'POST',
        body: JSON.stringify({no : boardNo}),
      });
      
      const result = await data.json();
      if(result.data.data === 1){
        window.location.href = `/${imageType === 1 ? 'main' : 'party'}Image/list`;
      }else{}
    }
  }

  if (writeComp === 0 && !viewData) {
    return (
      <div className="custompage-content">
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>{imageType === 1 ? '메인 상단' : '파티'} 이미지</title>
        </MetaTags>
        <div className="row">
          <div className="col-12 col">
            <form onSubmit={(e) => onSbm(e)}>
              <div className="card">
                <div className="card-body">
                  <div><h2>{imageType === 1 ? '메인 상단' : '파티'} 이미지</h2></div>
                  
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <label htmlFor={`pcSrc`} className="labelText">
                        이미지(1920*938)
                      </label>
                      {writeComp === 0 ? 
                        <p><img src={`https://d3txsylzm2ga7z.cloudfront.net/${viewData.pc_src}`} alt="Thumbnail" /></p> :
                        ''
                      }
                      
                      <Label className="fileAddName m-0 h-100 d-flex align-items-center">
                        <Input
                          type="file"
                          id="pcSrc"
                          innerRef={pcSrcRef}
                        />
                        <span
                          style={{
                            backgroundColor: "#FF5913",
                            border: "1px solid #FF5913",
                            padding: "8px 12px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          업로드
                        </span>
                      </Label>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <label htmlFor={`mSrc`} className="labelText">
                        모바일 이미지(720*1181)
                      </label>
                      {writeComp === 0 ? 
                        <p><img src={`https://d3txsylzm2ga7z.cloudfront.net/${viewData.m_src}`} alt="Thumbnail" /></p> :
                        ''
                      }
                      
                      <Label className="fileAddName m-0 h-100 d-flex align-items-center">
                        <Input
                          type="file"
                          id="mSrc"
                          innerRef={mSrcRef}
                        />
                        <span
                          style={{
                            backgroundColor: "#FF5913",
                            border: "1px solid #FF5913",
                            padding: "8px 12px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          업로드
                        </span>
                      </Label>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <label htmlFor={`index`} className="labelText">출력 순서</label>
                        <input
                          type="text" id="index"
                          className="js-input-mask form-control customInput"
                          ref={indexRef} defaultValue={writeComp === 0 ? viewData.index: ''}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="rowBorderBottom pt-3 pb-3">
                    <Col lg={12} className="d-flex flex-column">
                      <div>
                        <p className="labelText">숨김</p>
                        <p>
                          <label htmlFor="view1">
                            <input
                              type="radio" id="view1" name="view" value={0}
                              onClick={(e) => setView(Number(e.target.value))}
                              checked={view === 0}
                            /> 숨김
                          </label>
                          <label htmlFor="view2">
                            <input
                              type="radio" id="view2" name="view" value={1}
                              onClick={(e) => setView(Number(e.target.value))}
                              checked={view === 1}
                            /> 출력
                          </label>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="me-2"
                    onClick={(e) => onSbm(e)}
                  >
                    적용
                  </Button>
                  {writeComp === 0 ?
                    <Button
                    color="primary"
                    className="me-2"
                    onClick={imageDel}
                  >
                    삭제
                  </Button>
                  : ''
                  }
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
